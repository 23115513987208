@use "global/mixin" as m;
@use "global/variable" as v;

.l-main {
  padding: 150px 0 0;
  @include m.tb {
    padding: 100px 0 0;
  }
  &--padMid {
    padding: 150px 0 0;
    @include m.tb {
      padding: 100px 0 0;
    }
    @include m.sp {
      padding: 80px 0 0;
    }
  }
}