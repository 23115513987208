@use "global/mixin" as m;
@use "global/variable" as v;

.p-articlesSec {
  margin: 0 0 40px;
  @include m.sp {
    margin: 0 0 20px;
  }
}

.p-articlesContent {
  margin: 0 0 20px;
}

.p-articlesIndexBtnWrap {
  text-align: center;
  margin: 40px auto 0;
  @include m.sp {
    margin: 20px auto 0;
  }
}

.p-articlesImgOneCol {
  max-width: 480px;
  margin: 0 auto 20px;
  &--wide {
    max-width: 100%;
  }
}

.p-articlesCap {
  @include m.sp {
    font-size: 1.6rem;
  }
}
