@use "global/mixin" as m;
@use "global/variable" as v;


.p-news {
  margin: 120px 0 20px;
  @include m.sp {
    margin: 0;
  }
  &_item {
    padding: 0 0 15px;
    margin: 0 0 10px;
    display: block;
    border-bottom: 1px dotted v.$gray-light;
    @include m.hoverOpa;
    &:last-of-type {
      margin: 0;
      border-bottom: 0;
      padding: 0;
    }
  }
  &Date {
    @include m.fontSize(14);
    font-weight: 400;
    padding: 0 0 5px;
    @include m.sp {
      @include m.fontSize(18);
    }
  }
  &Desc {
    @include m.fontSize(14);
    font-weight: 500;
    @include m.sp {
      @include m.fontSize(20);
    }
  }
}
