@use "global/mixin" as m;
@use "global/variable" as v;

.l-wrap {
  padding: 0 20px;
  @include m.sp {
    padding: 0 10px;
  }
  &_inner {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}