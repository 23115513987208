@use "global/mixin" as m;
@use "global/variable" as v;

.p-reasonBg {
    background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/commitment/img_reason_meat.png);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: left 690px;
    @include m.sp {
        background-image: none;
    }
}

.p-reasonWrap {
    text-align: center;
    margin: 0 0 100px;
    @include m.sp {
        margin: 0 0 20px;
    }
    &_img {
        display: inline-block;
        width: 260px;
        @include m.sp {
            max-width: 179px;
        }
    }
}

.p-reasonSec {
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid v.$gray-ash;
    @include m.sp {
        margin: 0 0 20px;
        padding: 0 0 20px;
    }
    &:last-of-type {
        margin: 0;
        border: 0;
    }
    &_inner {
        display: flex;
        @include m.sp {
            display: block;
            width: 100%;
        }
    }
    &_titleSub {
        @include m.fontSize(24);
    }
    &_innerLeft {
        width: 55%;
        margin: 0 20px 0 0;
        @include m.sp {
            width: 100%;
        }
        &--img {
            width: 45%;
            margin: 0;
            @include m.sp {
                width: 100%;
            }
        }
        &--imgPos {
            @include m.sp {
                width: 75%;
                margin: 20px 0 0 -20px;
            }
        }
    }
    &_innerRight {
        width: 40%;
        @include m.sp {
            width: 100%;
        }
        &--detail {
            width: 55%;
            @include m.sp {
                width: 100%;
            }
        }
    }
    &_title {
        @include m.fontSize(30);
        font-weight: bold;
        display: flex;
        align-items: center;
        margin: 0 0 30px;
        @include m.sp {
            align-items: flex-end;
            font-size: 1.7rem;
            margin: 0 0 10px;
        }
    }
    &_txt {
        margin: 0 0 20px;
        @include m.sp {
            font-size: 1.2rem;
            margin: 20px 0 0;
        }
        &--mbWide {
            margin: 0 0 40px;
            @include m.sp {
                margin: 20px 0;
            }
        }
    }
    &_titleInner {
        line-height: 1;
    }
    &_titleNum {
        display: inline-block;
        margin: 0 15px 10px 0;
        @include m.sp {
            margin: 0 10px 0 0;
        }
        &--01 {
            width: 30px;
            @include m.sp {
                width: 6%;
            }
        }
        &--02 {
            width: 33px;
            @include m.sp {
                width: 6%;
            }
        }
        &--03 {
            width: 33px;
            @include m.sp {
                width: 6%;
            }
        }
        &--04 {
            width: 36px;
            @include m.sp {
                width: 7%;
            }
        }
        &--05 {
            width: 34px;
            @include m.sp {
                width: 7%;
            }
        }
    }
    &_img {
        width: 100%;
        &--01 {
            max-width: 400px; 
            @include m.sp {
                width: 70%;
                margin: 0 auto;
            }
        }
        &--03 {
            max-width: 400px;
            margin: 30px 0 0;
            @include m.sp {
                width: 70%;
                margin: 0 auto;
            }
        }
        &--04 {
            max-width: 375px;
            margin: 80px 0 0;
            @include m.sp {
                width: 70%;
                margin: 0 auto;
            }
        }
        &--05 {
            max-width: 410px;
            margin: 80px 0 0;
            @include m.sp {
                width: 90%;
                margin: 0 auto;
            }
        }
    }
}

.p-reasonCols {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    @include m.sp {
        margin: 20px 0;
    }
    &_item {
        width: 45%;
        display: flex;
        flex-direction: column;
        @include m.sp {
            width: 47%;
        }      
    }
    &_title {
        @include m.fontSize(20);
        font-weight: bold;
        margin: 0 0 10px;
        @include m.sp {
            margin: 0 0 5px;
            font-size: 1.4rem;
        } 
    }
    &_img {
        margin: auto 0 0;
    }
    &_txt {
        margin: 5px 0;
        @include m.sp {
            margin: 0 0 5px;
            font-size: 1.2rem;
        } 
    }
    &_txt--mb {
        margin: 0 0 20px;
    }
}

.p-reasonDef {
    margin: 0 0 40px;
    @include m.sp {
        margin: 20px 0 0;
    }
    &_term {
        @include m.fontSize(20);
        font-weight: bold;
        margin: 0 0 10px;
        @include m.sp {
            font-size: 1.4rem;
            margin: 0 0 5px;
        } 
    }
    &_desc {
        @include m.sp {
            font-size: 1.2rem;
        }
    } 
}

.p-reasonLists {
    &_item {
        padding-left: 1rem;
        text-indent: -1rem;
    }
    &_mark {
        color: v.$green;
    }
}

.p-reasonImgCenterWrap {
    margin: 20px 0 0;
    text-align: center;
    &_img {
        max-width: 350px;
        width: 100%;
        display: inline-block;
        @include m.sp {
            max-width: none;
            width: 60%;
        }
    }
}

