@use "global/mixin" as m;
@use "global/variable" as v;

.l-footer {
  position: relative;
  margin: 50px 0 0;
  @include m.sp {
    padding: 0;
    margin: 20px 0 0;
  }
  &_logoWrap {
    text-align: center;
    margin: 0 0 -100px;
    z-index: 1;
    position: relative;
    @include m.sp {
      width: 60%;
      margin: 0 0 -50px auto;
    }
  }
  &_logoLink {
    display: inline-block;
    width: 20%;
    max-width: 180px;
    @include m.sp {
      max-width: 90px;
      width: 100%;
    }
  }
  &_navSns {
    margin: 120px 0 40px;
    @include m.sp {
      width: 60%;
      margin: 60px 0 20px auto;
    }
  }
  &_navSnsTitle {
    text-align: center;
    font-weight: bold;
    @include m.fontSize(14);
    @include m.sp {
      font-size: 1.1rem;
    }
  }
  &_nav {
    display: flex;
    justify-content: space-between;
    @include m.sp {
      display: block;
    }
  }
  &_copy {
    @include m.fontSize(12);
    text-align: center;
    color: v.$gray-deep;
    padding: 30px 0;
  }
}

.l-footerBg {
  background-color: v.$orange-pale;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin: 0 0 -10px;
  position: relative;
  overflow: hidden;
  @include m.sp {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0 0 -5px;
  }
}

.l-footerBgImg {
  //margin: -50px 0 0;
}

.l-footerBgImg03 {
  position: absolute;
  top: 0;
  left: 0;
  width: 18%;
  z-index: 1;
  @include m.sp {
    width: 38%;
    max-width: 180px;
  }
}

.l-footerBgImg02 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25%;
  @include m.sp {
    width: 35%;
    max-width: 220px;
  }
}


.l-footerSnsLists {
  display: flex;
  justify-content: center;
  margin: 15px 0 0;
  align-items: center;
  @include m.sp {
    margin: 10px 0 0;
  }
  &_item {
    margin: 0 20px;
    @include m.hoverOpa;
    @include m.sp {
      margin: 0 10px;
    } 
  }
  &_img {
    &--twitter {
      width: 42px;
    }
    &--youtube {
      width: 48px;
    }
    &--facebook {
      width: 40px;
    }
    &--instagram {
      width: 40px;
    }
  }
}

.l-footerNavCols {
  
  &--noTtl {
    margin: 40px 0 0;
    @include m.sp {
      margin: 10px 0 0;
    }
  }
}

.l-footerNavTtlWrap {
  @include m.sp {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
  }
}

.l-footerNavTtl {
  margin: 0 0 10px;
  @include m.sp {
    width: 50%;
  }
}

.l-footerNavTtl {
  &_link {
    display: flex;
    font-family: v.$corpo;
    @include m.fontSize(22);
    @include m.hoverOpa;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &_img {
    margin: 0 10px 0 0;
    &--shop {
      width: 23px;
      @include m.sp {
        width: 20px;
      }
    }
    &--menu {
      width: 16px;
      @include m.sp {
        width: 17px;
        margin: 0 12px 0 0;
      }
    }
    &--takeout {
      width: 18px;
    }
    &--delivery {
      width: 24px;
      margin: 0 4px 0 0;
      @include m.sp {
        margin: 0 8px 0 0;
      }
    }
    &--mobile {
      width: 17px;
    }
    &--beef {
      width: 27px;
      @include m.sp {
        width: 24px;
        margin: 0 4px 0 0;
      }
    }
  }
}

.l-footerNavChild {
  margin: 0 0 10px;
  @include m.sp {
    display: flex;
    flex-wrap: wrap;
  }
  &--shop {
    padding-left: 33px;
    @include m.sp {
      padding: 0;
    }
  }
  &--menu {
    padding-left: 28px;
    @include m.sp {
      padding: 0;
    }
  }
  &--beef {
    padding-left: 37px;
    @include m.sp {
      padding: 0;
    }
  }
  &_item {
    @include m.fontSize(14);
    position: relative;
    padding: 0 0 0 10px;
    margin: 0 0 5px;
    @include m.hoverOpa;
    @include m.sp {
      width: 50%;
      font-size: 1.1rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
   }
  }
}

.l-FooterWrap {
  @include m.sp {
    margin: 0 0 15vw;
  }
}

.l-footerFixedNavWrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.l-footerCampaign {
  background: v.$white;
  border-radius: 100%;
  display: inline-block;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  width: 80px;
  height: 80px;
  @include m.sp {
    position:unset;
    margin: 0 10px 10px auto;
    display: block;
    width: 65px;
    height: 65px;
  }
  &_img {
    width: 30px;
    margin: 0 auto;
  }
  &_link {
    display: inline-block;
    border: 2px solid v.$red;
    background: v.$yellow;
    @include m.fontSize(12);
    @include m.hoverOpa;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: v.$red;
    line-height: 1;
    font-weight: bold;
    position: relative;
    @include m.sp {
      width: 65px;
      height: 65px;
    }
    &:before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      background: v.$red;
      border-radius: 100px;
      right: 2px;
      top: 5px;
      @include m.sp {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.l-footerFixedNav {
  display: none;
  @include m.sp {
    border-top: 2px solid v.$red-deep;
    background: v.$orange-pale;
    display: flex;
    
    padding: 10px;
    justify-content: space-between;
  }
  &_icon {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }
  &_title {
    font-family: v.$corpo;
    font-size: 2.1rem;
  }
  &_intro {
    font-weight: bold;
    font-size: 1.2rem;
  }
  &_introClr {
    color: v.$red-deep;
  }
  &_btn {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    min-width: 110px;
    padding: 0 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background: v.$white;
    position: relative;
    @include m.hoverOpa;
  }
  &_btnTxt {
    margin: 0 auto;
  }
  &_arrow {
    width: 7px;
  }
}