@use "global/mixin" as m;
@use "global/variable" as v;

.p-contactIntro {
  text-align: center;
  @include m.sp {
    font-size: 1.2rem;
    margin: 0 0 20px;
  }
}

.p-contactCont {
  margin: 80px 0 0;
  @include m.sp {
    margin: 0;
  }
}

.p-contactItem {
  margin: 0 0 5px;
  display: flex;
  @include m.sp {
    display: block;
    margin: 0 0 10px;
  }
  &_left {
    background: v.$gray-clear;
    max-width: 280px;
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    @include m.sp {
      max-width: 100%;
      padding: 15px;
      font-size: 1.4rem;
      margin: 0 0 5px;
    }
  }
  &_tag {
    background: v.$red-light;
    color: v.$white;
    @include m.fontSize(14);
    line-height: 1;
    padding: 3px 4px;
    border-radius: 3px;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &_right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 0 20px;
    width: calc(100% - 300px);
    padding: 20px 0;
    @include m.sp {
      padding: 0;
      margin: 0 0 20px;
      width: 100%;
    }
  }
  &_input {
    width: 100%;
    max-width: 600px;
  }
  &_radioWrap {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
  }
  &_selectWrap {
    max-width: 160px;
    width: 32%;
    margin: 0 5px 5px 0;
    &:last-of-type {
      margin: 0 0 5px 0;
    }
  }
}

.p-contactSubmitWrap {
  text-align: center;
  margin: 50px 0 0;
  @include m.sp {
    margin: 30px 0 0;
  }
}