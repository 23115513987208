@use "global/mixin" as m;
@use "global/variable" as v;

.p-policyTitle {
  text-align: center;
  @include m.fontSize(40);
  font-weight: bold;
  margin: 0 0 50px;
  @include m.sp {
    padding: 20px 0 0;
    margin: 0 0 20px;
    font-size: 2.5rem;
  }
}

.p-policySec {
  margin: 0 0 50px;
  @include m.sp {
    margin: 0 0 30px;
  }
  &_title {
    @include m.fontSize(20);
    font-weight: bold;
    margin: 0 0 10px;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}

.p-policyPara {
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.2rem;
    margin: 0 0 10px;
  }
  &--right {
    text-align: right;
  }
}

.p-policyLists {
  margin: 0 0 20px;
  &--indent {
    margin: 0 0 20px 40px;
    @include m.sp {
      margin: 0 0 20px 1.6rem;
    }
  }
  &_item {
    margin: 0 0 5px;
    padding-left: 1rem;
    text-indent: -1rem;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
}