@use "global/mixin" as m;
@use "global/variable" as v;

.c-tag {
  @include m.fontSize(12);
  padding: 3px 5px;
  margin: 0 10px;
  line-height: 1;
  display: inline-block;
  color: v.$white;
  &--orange {
    background: v.$orange;
  }
  &--red {
    background: v.$red-deep;
  }
  &--green {
    background: v.$green-deep;
  }
}
