@use "global/mixin" as m;
@use "global/variable" as v;

.p-wingTitleWrap {
    text-align: center;
    margin: 0 0 20px;
    @include m.sp {
        margin: 0 0 20px;
    }
    &_img {
        display: inline-block;
        width: 460px;
        @include m.sp {
            max-width: 327px;
        }
    }
}

.p-wingCont {
    padding: 47% 0 0;
    background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/commitment/bg_wing_pc.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include m.sp {
        background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/commitment/bg_wing_sp.png);
    }
    &_inner {
        display: flex;
        max-width: 860px;
        width: 100%;
        margin: 0 auto;
    }
}

.p-wingContLeft {
    width: 50%;
    border-right: 5px solid v.$yellow;
    padding: 0 60px 0 0;
    @include m.sp {
        padding: 8vw 5vw 0 0;
        border-right: 0.8vw solid v.$yellow;
    }
}

.p-wingContRight {
    width: 50%;
    padding: 100px 0 0 60px;
    @include m.sp {
        padding: 18vw 0 0 5vw;
    }
}

.p-wingShop {
    margin: 0 0 50px;
    @include m.sp {
        margin: 0 0 6vw;
    }
    &--mbWide {
        margin: 0 0 100px;
        @include m.sp {
            margin: 0 0 11vw;
        }
    }
    &:last-of-type {
        margin: 0;
    }
    &_img {
        margin: 0 0 10px;
        @include m.sp {
            margin: 0;
        }
    }
    &_dateRight,
    &_dateLeft {
        @include m.fontSize(20);
        font-weight: bold;
        position: relative;
        &:before {
            content:"";
            display: block;
            position: absolute;
            height: 3px;
            width: 50px;
            margin: auto;
            background: v.$black;
            z-index: 1;
            border-radius: 5px;
            top: 0;
            bottom: 0; 
            @include m.sp {
                height: 2px;
                width: 4vw;
            }
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            background: v.$black;
            border-radius: 50%;
            border: 3px solid v.$white;
            @include m.sp {
                width: min(3vw,15px);
                height: min(3vw,15px);
                border: 2px solid v.$white;
            }
        }
    }
    &_dateRight {
        text-align: right;
        &:before {
            right: -60px;
            @include m.sp {
                right: -6vw;
            }          
        }
        &:after {
            right: -72px;
            @include m.sp {
                right: -7vw;
            }
        }
    }
    &_dateLeft {
        text-align: left;
        &:before {
            left: -60px;
            @include m.sp {
                left: -6vw;
            }                     
        }
        &:after {
            left: -72px;
            @include m.sp {
                left: -6.8vw;
            }
        }
    }
    &_txtRight {
        @include m.fontSize(16);
        text-align: right;
    }
}
