@use "global/mixin" as m;
@use "global/variable" as v;

.p-consulattionSec {
    border-radius: 20px;
    box-shadow: 0 0 5px v.$gray-light02;
    overflow: hidden;
    margin: 0 0 40px;
    @include m.sp {
        border-radius: 10px;
        margin: 0 0 20px;
    }
    &:last-of-type {
        margin: 0;
    }
    &_link {
        @include m.hoverOpa;
    }
    &_title {
        text-align: center;
        @include m.fontSize(24);
        font-weight: bold;
        border-top: 2px solid v.$red-deep;
        padding: 20px;
        @include m.sp {
            font-size: 1.4rem;
            padding: 10px;
        }
    }
}