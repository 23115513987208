@use "global/mixin" as m;
@use "global/variable" as v;

.c-item {
  &_link {
    @include m.hoverOpa;
  }
  &_linkImgCamWrap {
    border-radius: 5px;
    box-shadow: 0 0 5px v.$gray;
    display: block;
    overflow: hidden;
    @include m.hoverOpa;
    margin: 0 0 5px;
    padding-bottom: 68%;
    height: 0;
    position: relative;
  }
  &_linkImgCam {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_linkImgWrap {
    border-radius: 20px;
    box-shadow: 0 0 5px v.$gray; 
    display: flex;
    align-items: center;
    overflow: hidden;
    @include m.hoverOpa;
    padding-bottom: 65%;
    position: relative;
    height: 0;
    @include m.sp {
      border-radius: 15px;
      position: relative;
      padding-bottom: 100%;
    }
  }
  &_linkImg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include m.sp {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: scale(1.6);
    }
  }
  &_title {
    @include m.fontSize(17);
    font-weight: bold;
  }
}