@use "global/mixin" as m;
@use "global/variable" as v;

.c-accr {
  @include m.sp {
    border: 1px solid v.$gray-light03;
    border-radius: 30px;
    
    color: v.$red-deep;
    @include m.sp {
      margin: 0 0 30px;
    }
  }
  &_itemHead {
    display: none;
    @include m.sp {
      padding: 10px 20px;
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  &_cont {
    @include m.pc {
      display: block !important;
    }
    @include m.sp {
      display: none;
      padding: 0 20px 10px;
    }
  }
}

.c-accrLists {
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
  width: 60%;
  margin: 0 auto;
  justify-content: center;
  gap: 15px 25px;
  @include m.sp {
    display: block;
    width: 100%;
  }
  &_item {
    font-weight: bold;
    color: v.$red-deep;
    text-decoration: underline;
    @include m.sp {
      font-weight: bold;
      color: v.$black;
      text-decoration: none;
      font-size: 1.2rem;
      border-bottom: 1px dotted v.$gray-light03;
      &:last-of-type {
        border-bottom: none;
      }
    }
    
  }
  &_itemLink {
    @include m.hoverOpa;
    @include m.sp {
      display: block;
      padding: 10px 0;
    }
  }
  &_itemArrow {
    @include m.sp {
      display: none;
    }
    &--accrBtn {
      display: in;
    }
  }
  &_itemArrowSwich {
    display: inline-block;
    &.is-up {
      transform: rotate(180deg);
      position: relative;
      top: 3px;
    }
  }
}