@use "global/mixin" as m;
@use "global/variable" as v;

.c-hedingMenu {
  margin: 0 0 10px;
  @include m.tb {
    background-size: cover;
    background-position: center;
    margin: 0 0 10px;
    border-radius: 20px;
  }
  &_img {
    width: 100%;
  }
}

.c-headingRoundWrap {
  position: relative;
  top: 30px;
  z-index: 1;
  @include m.sp {
    position: relative;
    top: 4vw;
  }
}

.c-headingRound {
  font-weight: bold;
  color: v.$white;
  border-radius: 50px;
  display: block;
  padding: 15px 10px;
  justify-content: center;
  text-align: center;
  @include m.fontSize(26);
  @include m.sp {
    padding: 5px;
    font-size: 2.1rem;
  }
  &--red {
    background-color: v.$red-deep;
    border: 2px solid v.$red-deep;
  }
}