@use "global/mixin" as m;
@use "global/variable" as v;

.p-ingredientsTitleWrap {
    text-align: center;
    margin: 0 0 20px;
    &_img {
        display: inline-block;
        width: 345px;
        @include m.sp {
            max-width: 250px;
        }
    }
}

.p-ingredientsHeading {
    position: relative;
    background: v.$black-iron02;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 30px 0;
    margin: 0 0 40px;
    @include m.sp {
        padding: 15px;
        margin: 0 0 10%;
    }
    &_img {
        position: absolute;
        right: 10px;
        top: -30px;
        max-width: 272px;
        width: 100%;
        @include m.sp {
            right: 2%;
            top: 5%;
            width: 40%;
        }
    }
    &_txt {
        text-align: center;
        color: v.$white;
        @include m.fontSize(30);
        font-weight: bold;
        position: relative;
        z-index: 1;
        @include m.sp {
            text-align: left;
            font-size: 1.5rem;
        }
    }
}

.p-ingredientsSec {
    margin: 0 0 20px;
    &:last-of-type {
        margin: 0;
    }
    &_imgWrap {
        max-width: 440px;
        width: 100%;
    }
}

.p-ingredientsIntro {
    text-align: center;
    @include m.fontSize(24);
    font-weight: bold;
    margin: 0 0 60px;
    @include m.sp {
        margin: 0 0 30px;
    }
    &_green {
        color: v.$green;
    }
}

.p-ingredientsCont {
    padding: 0 20px;
    @include m.sp {
        padding: 0;
    }
}

.p-ingredientsSec {
    display: flex;
    &_box {
        padding: 30px;
        border-radius: 20px;
        background: v.$white;
        opacity: 0.8;
        max-width: 600px;
        width: 80%;
        margin: 40px 0;
        @include m.sp {
            margin: 20px 0 0;
            border-radius: 10px;
            padding: 10px;
            width: 90%;
        }
        &--right {
            margin-left: -70px;
        }
        &--left {
            margin-right: -70px;
        }
    }
    &_numWrap {
        width: 30px;
        display: inline-block;
        @include m.sp {
            width: 11%;
        }
    }
    &_title {
        font-weight: bold;
        @include m.fontSize(20);
        margin: 0 0 10px;
        @include m.sp {
            font-size: 1.4rem;
            margin: 0 0 5px;
        }
    }
    &_txt {
        @include m.sp {
            font-size: 1.2rem;
        }
    }
}

.p-ingredientsImgFlow {
    max-width: 600px;
    width: 80%;
    margin: 60px auto;
    @include m.sp {
        width: 100%;
        margin: 10px auto 30px;
    }
}
