@use "global/mixin" as m;
@use "global/variable" as v;

.p-mobileFlow {
  padding: 60px 40px 30px;
  @include m.sp {
    padding: 40px 20px 10px;
  }
  &_sec {
    margin: 0 auto 20px;
    max-width: 800px;
    &:last-of-type {
      margin: 0 auto;
    }
  }
  &_tilte {
    border-bottom: 1px solid v.$black;
    padding: 0 0 10px;
    margin: 0 0 20px;
    display: flex;
    align-items: flex-end;
    @include m.fontSize(24);
    font-weight: bold;
    color: v.$red-deep;
    @include m.sp {
      font-size: 1.6rem;
      margin: 0 0 10px;
    }
  }
  &_img {
    margin: 0 30px 0 0;
    margin: 0 30px 0 0;
    &--st01 {
      width: 30px;
    }
    &--st02 {
      width: 33px;
    }
    &--st03 {
      width: 33px;
    }
    &--st04 {
      width: 33px;
    }
    @include m.sp {
      margin: 0 20px 0 0;
    }
  }
  &_content {
    max-width: 730px;
    margin: 0 0 0 auto;
    @include m.sp {
      max-width: 670px;
      margin: 0 auto;
      font-size: 1.2rem;
    }
  }
  &_tyu {
    @include m.fontSize(16);
    color: v.$red-deep;
    text-indent: -1rem;
    padding-left: 1rem;
    display: block;
    margin: 10px 0 0;
    @include m.sp {
      font-size: 1.1rem;
    }
  }
}

.p-mobileFlowCols {
  display: flex;
  @include m.sp {
    flex-direction: column;
  }
  &_item {
    &:first-child {
      max-width: 160px;
      margin: 0 30px 0 0;
      @include m.sp {
        margin: 0 auto;
        order: 2;
      }
    }
    &:last-child {
      width: 100%;
      @include m.fontSize(20);
      font-weight: bold;
      @include m.sp {
        order: 1;
        margin: 0 0 20px;
        font-size: 1.4rem;
      }
    }
  }
}

.p-mobileFlowWrap {
  @include m.sp {
    margin: 0 0 30px;
  }
}

.p-mobileAcrBtnPc {
  display: block;
  &_inner {
    cursor: auto;
    &:hover {
      opacity: 1;
    }
  }
  @include m.sp {
    display: none;
  }
}

.p-mobileAcrBtnSp {
  display: none;
  @include m.sp {
    display: block;
  }
}

.p-mobileAcrCont {
  @include m.pc {
    display: block !important;
  }
}

