@use "global/mixin" as m;
@use "global/variable" as v;

.p-app {
  &Bg {
    background: v.$brown-dark url('/webap/wp-content/themes/pepperlunch/assets/img/appli/bg_food.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin: 0 auto 60px;
    @include m.sp {
      margin: 0 auto 30px;
      background-size: cover;
    }
  }
}

.p-appIcon {
  max-width: 100px;
  width: 100%;
  margin: 0 auto 20px;
  @include m.sp {
    max-width: 75px;
    margin: 20px auto;
  }
}

.p-appTitle {
  @include m.fontSize(48);
  text-align: center;
  font-weight: bold;
  color: v.$white;
  margin: 0 0 20px;
  @include m.sp {
    font-size: 2.4rem;
    margin: 0 0 10px;
  }
}

.p-appIntro {
  @include m.fontSize(24);
  color: v.$white;
  text-align: center;
  font-weight: 500;
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.2rem;
  }
}

.p-appMobile {
  max-width: 583px;
  margin: 0 0 20px auto;
  @include m.sp {
    margin: 0 auto 20px;
  }
}

.p-appDl {
  display: flex;
  max-width: 490px;
  margin: 0 auto;
  justify-content: space-between;
  &_app {
    max-width: 210px;
    width: 43%;
    @include m.sp {
      max-width: 100%;
      width: 45%;
    }
  }
  &_ggl {
    max-width: 236px;
    @include m.sp {
      max-width: 100%;
      width: 50%;
    }
  }
}