@use "global/mixin" as m;
@use "global/variable" as v;

 .c-bread {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 10px min(26%, 270px);
  color: v.$white;
  position: relative;
  font-weight: bold;
  @include m.sp {
    display: none;
  }
  &--left {
    padding: 0 0 10px 20px;
  }
  &_child {
    position: relative;
    padding: 0 20px 0 0;
    margin: 0 10px 0 0;
    display: inline-block;
    &:after {
      position: absolute;
      margin: auto;
      right: 0;
      top: 0;
      bottom: 0;
      content:"";
      width: 10px;
      height: 10px;
      background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/icon_arrow_bread.svg);
    }
    &:last-of-type {
      padding: 0;
      margin: 0;
      &:after {
        content: none;
      }
    }
  }
  &_link {
    text-decoration: underline;
  }
 }