@use "global/mixin" as m;
@use "global/variable" as v;

.p-initiativesWrap {
    text-align: center;
    margin: 0 0 40px;
    @include m.sp {
        margin: 0 0 20px;
    }
    &_img {
        display: inline-block;
        width: 285px;
        @include m.sp {
            max-width: 204px;
        }
    }
}

.p-initiativesSec {
    margin: 0 0 120px;
    @include m.sp {
        margin: 0 0 40px;
    }
    &:last-of-type {
        margin: 0;
    }
    &_title {
        @include m.fontSize(30);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 30px;
        padding: 0 0 20px;
        border-bottom: 1px solid v.$gray-ash;
        @include m.sp {
            font-size: 1.8rem;
            padding: 0 0 15px;
            margin: 0 0 20px;
        }
    }
    &_num {
        width: 30px;
        display: inline-block;
        margin: 0 15px 0 0;
        @include m.sp {
            width: 7%;
            margin: 0 3% 0 0;
        }
    }
    &_imgWrap {
        text-align: center;
        margin: 0 0 60px;
        @include m.sp {
            margin: 0 0 20px;
        }
    }
    &_img {
        display: inline-block;
        &--01 {
            max-width: 475px;
            width: 100%;
            @include m.sp {
                max-width: none;
                width: 70%;
            }
        }
        &--02 {
            max-width: 278px;
            width: 100%;
            @include m.sp {
                max-width: none;
                width: 55%;
            }
        }
        &--03 {
            max-width: 480px;
            width: 100%;
            @include m.sp {
                max-width: none;
                width: 75%;
            }
        }
    }
}

.p-initiativesSecChild {
    margin: 0 0 50px;
    @include m.sp {
        margin: 0 auto 20px;
    }
    &_title {
        color: v.$green;
        text-align: center;
        font-weight: bold;
        @include m.fontSize(20);
        margin: 0 0 10px;
        @include m.sp {
            font-size: 1.4rem;
            margin: 0 0 5px;
        }
    }
    &_txt {
        max-width: 800px;
        margin: 0 auto;
        @include m.sp {
            width: 80%;
            font-size: 1.2rem;
        }
    }
}

