@use "global/mixin" as m;
@use "global/variable" as v;

.p-cpnCols {
  margin: 120px 0 100px;
  @include m.sp {
    margin: 0;
  }
}

.p-cpnItem {
  &_title {
    @include m.fontSize(14);
    position: relative;
    padding: 0 0 0 10px;
    font-weight: bold;
    @include m.sp {
      font-size: 1.4rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
      @include m.sp {
        top: 0.7rem;
      }
    }
  }
  &_txt {
    @include m.fontSize(12);
    @include m.sp {
      font-size: 1.2rem;
    }
  }
}
