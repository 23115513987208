@use "global/mixin" as m;
@use "global/variable" as v;

.p-commitmentSec {
    border-radius: 20px;
    box-shadow: 0 0 5px v.$gray-light02;
    overflow: hidden;
    margin: 0 0 40px;
    @include m.sp {
        border-radius: 10px;
        margin: 0 0 20px;
    }
    &--half {
        width: 48%;
        @include m.sp {
            width: 100%;
        }
    }
    &_link {
        @include m.hoverOpa;
    }
    &_title {
        text-align: center;
        @include m.fontSize(24);
        font-weight: bold;
        border-top: 2px solid v.$red-deep;
        padding: 20px;
        @include m.sp {
            font-size: 1.4rem;
            padding: 10px;
        }
    }
}

.p-commitmentSecWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include m.sp {
        display: block;
    }
}


