/* ブラウザのUAスタイルシートのすべてのスタイルを削除します、displayは除く */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* box-sizingの優先値 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* リストのスタイル（箇条書き・番号）を削除します */
ol,
ul {
  list-style: none;
}

/* 画像がコンテナを超えないようにするため */
img {
  max-width: 100%;
  display: block;
}

/* テーブルのセル間のスペースを削除します */
table {
  border-collapse: collapse;
}

a {
  cursor: pointer;
}
