@use "global/mixin" as m;
@use "global/variable" as v;

.p-detailItem {
  display: flex;
  margin: 0 0 40px;
  @include m.tb {
    display: block;
  }
  @include m.sp {
    margin: 0 0 20px;
  }
  &_imgWrap {
    max-width: 600px;
    margin: 0 30px 0 0;
    width: calc(60% + 30px);
    @include m.tb {
      margin: 0 auto;
      width: 100%;
    }
  }
  &_imgInner {
    border-radius: 20px;
    box-shadow: 0 0 5px v.$gray-light02;
    overflow: hidden;
    @include m.sp {
      height: 85vw;
      width: 100%;
      border-radius: 10px;
      margin: 0 auto;
      position: relative;
    }
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include m.sp {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: scale(1.6);
    }
  }
  &_info {
    padding: 40px 0 0;
    width: calc(40% - 30px);
    @include m.tb {
      padding: 0;
      width: 100%;
    }
  }
}

.p-detailItemBox {
  width: calc(100% + 70px);
  box-shadow: 0 0 5px v.$gray-light02;
  border-radius: 10px;
  background: v.$red-deep;
  padding: 30px;
  position: relative;
  right: 70px;
  color: v.$white;
  margin: 0 0 30px;
  @include m.tb {
    width: 100%;
    right: 0;
    max-width: 500px;
    margin: -40px auto 30px;
  }
  @include m.sp {
    width: 90%;
    right: 0;
    max-width: 500px;
    margin: -6% auto 20px;
    padding: 20px;
  }
  &_name {
    @include m.fontSize(28);
    font-weight: bold;
    margin: 0 0 20px;
    @include m.sp {
      margin: 0 0 10px;
    }
  }
  &_nameEn {
    @include m.fontSize(16);
    display: block;
    line-height: 1;
  }
  &_intro {
    line-height: 1.4;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
}

.p-detailItemTxtArea {
  @include m.fontSize(14);
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid v.$black;
  @include m.sp {
    font-size: 1.2rem;
    padding: 0 0 10px;
    margin: 0 0 10px;
  }
}

.p-detailItemValue {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  @include m.tb {
    justify-content: flex-end;
    gap: 0; 
  }
  &_size {
    @include m.fontSize(12);
    background: v.$red-deep;
    color: v.$white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 0 5px 0 0;
    line-height: 1.5;
    @include m.sp {
      width: 4vw;
      height: 4vw;
    }
  }
  &_cont {
    display: flex;
    align-items: flex-end;
    line-height: 1;
    @include m.tb {
      margin: 0 20px 0 0;
    }
    &:first-of-type {
      .p-detailItemValue_size {
        @include m.fontSize(20);
        width: 30px;
        height: 30px;
        @include m.sp {
          font-size: 1.5rem;
          width: 8vw;
          height: 8vw;
        }
      }
      .p-detailItemValue_num {
        @include m.fontSize(46);
        @include m.sp {
          font-size: 3.4rem
        }
      }
      .p-detailItemValue_yen {
        @include m.fontSize(26);
        @include m.sp {
          font-size: 2rem
        }
      }
    }
    &:last-of-type {
      @include m.tb {
        margin: 0;
      }
    }
  }
  &_num,
  &_yen {
    @include m.fontSize(18);
    font-weight: bold;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}

.p-detailOption {
  &_title {
    @include m.fontSize(18);
    color: v.$red-deep;
    font-weight: bold;
    margin: 0 0 5px;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &_box {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid v.$gray-thin02;
    padding: 30px;
    border-radius: 10px;
    gap: 20px;
    @include m.sp {
      padding: 20px;
    }
  }
  &_item {
    width: calc(25% - 15px);
    @include m.sp {
      width: calc(50% - 10px);
    }
  }
  &_name {
    @include m.fontSize(18);
    font-weight: bold;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &_itemLink {
    @include m.hoverOpa;
  }
  &_imgWrap {
    border-radius: 10px;
    box-shadow: 0 0 5px v.$gray-light02;
    overflow: hidden;
    @include m.sp {
      height: 34vw;
      width: 100%;
      position: relative;
    }
  }
  &_img {
    @include m.sp {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: scale(1.4);
    }
  }
}

.p-detailSide {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
  gap: 0 15px;
  @include m.sp {
    margin: 20px 0 0;
    display: block;
  }
  &_link {
    width: 50%;
    @include m.hoverOpa;
    @include m.sp {
      margin: 0 0 20px;
      display: block;
      width: 100%;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
