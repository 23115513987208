@use "global/mixin" as m;
@use "global/variable" as v;

.c-cols {
  display: flex;
  gap: 20px 22px;
  @include m.sp {
    gap: 20px;
  }
  flex-wrap: wrap;
  &--between {
    justify-content: space-between;
  }
  &_itemOneThird {
    //$calc:calc(100%/3);
    width: calc(100%/3 - 15px);
    @include m.sp {
      width: calc(50% - 10px);
    }
  }
  &_itemHalf {
    //$calc:calc(100%/3);
    width: 48%;
    @include m.sp {
      width: 100%;
    }
  }
}