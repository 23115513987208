@use "global/mixin" as m;
@use "global/variable" as v;

.p-wayWrap {
    text-align: center;
    margin: 0 0 30px;
    @include m.sp {
        margin: 0 0 20px;
    }
    &_img {
        display: inline-block;
        width: 511px;
        @include m.sp {
            max-width: 321px;
        }
    }
}

.p-waySec {
    margin: 0 0 20px;
    &_title {
        margin: 0 0 20px;
    }
    &_img {
        width: 80%;
        margin: 0 auto;
    }
}

