@charset "UTF-8";


//color
$navy: #003676;
$red: #d41518;
$red-light: #ff0000;
$red-deep: #c60000;
$yellow: #f1e527;
$orange-pale: #f4bf42;
$orange: #ff840a;
$gray-dark: #535353;
$gray-light: #bbb;
$gray-light02: #ababab;
$gray-light03: #bababa;
$gray-deep: #666;
$gray: #707070;
$gray-smork: #999;
$gray-ash: #d0d0d0;
$gray-clear: #ebebeb;
$gray-thin: #c4c4c4;
$gray-thin02: #c8c8c8;
$green-deep: #42840a;
$green: #38af00;
$green-light: #00af5c;
$white: #fff;
$black: #000;
$black-iron: #333;
$black-iron02: #3d3d3d;
$pink-clear: #fff3f4;
$brown-dark: #322A28;


//font-family
$corpo: 'Corporate';


