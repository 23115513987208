@use "global/mixin" as m;
@use "global/variable" as v;



.p-indexMv {
  display: none;
  margin: 0 0 3%;
  &.slick-initialized {
    display: block;
  }
  & &_item {
    transition: all 300ms ease;
    transform: scale(0.92);
    box-shadow: 0 0 10px v.$gray-deep;
    border-radius: 20px;
    overflow: hidden;
    margin: 10px 0;
    @include m.sp {
      margin: 10px 5px;
    }
    &.slick-center {
      transform: scale(1);
    }
  }
  & &_img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &--pc {
      display: block;
      @include m.sp {
        display: none;
      }
    }
    &--sp {
      display: none;
      @include m.sp {
        display: block;
      }
    }
  }
  &_link,
  &_imgWrap {
    padding-bottom: 45%;
    position: relative;
    height: 0;
    display: block;
    @include m.sp {
      padding-bottom: 100%;
    }
  }
}

.p-indexThumbWrap {
  display: flex;
  justify-content: center;
}

.p-indexThumb {
  margin: 0 auto 40px;
  width: 60%;
  @include m.sp {
    min-width: 200px;
    margin: 30px auto 40px;
  }
  &_item {
    margin: 0 10px;
    overflow: hidden;
    max-width: 150px;
    @include m.sp {
      padding-bottom: 6%;
    }
    &:hover {
      cursor: pointer;
    }
    @include m.sp {
      margin: 0 5px;
      max-width: 40px;
    }
    &.slick-current img {
      border: 2px solid v.$red-light;
    }
  }
  .p-indexThumb_img {
    border-radius: 5px;
    &--pc {
      display: block;
      @include m.sp {
        display: none;
      }
    }
    &--sp {
      display: none;
      @include m.sp {
        display: block;
      }
    }
  }
}

.slick-prev--thumb,
.slick-next--thumb {
  width: 40px;
  height: 40px;
  @include m.sp {
    width: 30px;
    height: 30px;
    top: 25%;
  }
  &:before {
    content: "";
    width: 40px;
    height: 40px;
    opacity: 1;
    display: block;
    @include m.sp {
      width: 30px;
      height: 30px;
    }
  }
}

.slick-prev--thumb {
  left: -40px;
  @include m.sp {
    left: -30px;
  }
  &:before {
    background: url(/webap/wp-content/themes/pepperlunch/assets/img/top/icon_slide_arrow.svg)!important;
    background-size: contain!important;
  }
}

.slick-next--thumb {
  right: -40px;
  @include m.sp {
    right: -30px;
  }
  &:before {
    background: url(/webap/wp-content/themes/pepperlunch/assets/img/top/icon_slide_arrow.svg)!important;
    background-size: contain!important;
    transform: rotate(180deg);
  }
}

.p-indexNewsBtnWrap {
  margin: 10% 0 50px;
  text-align: center;
  &:nth-of-type(n + 2) {
    margin: 5% 0 50px;
  }
  @include m.sp {
    margin: 40px 0 50px;
  }
}

.p-indexNewsSec {
  background: v.$orange-pale;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin: 130px 0 0;
  &_inner {
    padding: 40px 20px;
    position: relative;
    @include m.sp {
      padding: 150px 10px 30px;
    }
  }
}

.p-indexAnnounceWrap + .p-indexAnnounceWrap {
  .p-indexNewsBtnWrap {
    margin: 0 0 50px;
  }
}

.p-indexAnnounceWrap {
  margin: 0 0 50px;
  + .p-indexNewsSec {
    margin: 0;
  }
}

.p-indexNewsBg01 {
  position: absolute;
  width: 600px;
  top: 30px;
  left: -70px;
  @include m.sp {
    width: 75%;
    max-width: 450px;
  }
}

.p-indexNewsBg02 {
  position: absolute;
  width: 260px;
  bottom: -50px;
  right: -70px;
  &.lazyload {
    position: unset;
  }
  @include m.sp {
    display: none;
  }
}

.p-indexNewsBg03 {
  position: absolute;
  width: 140px;
  right: 0;
  bottom: -70px;
  @include m.sp {
    display: none;
  }
}

.p-indexNewsBg04 {
  display: none;
  @include m.sp {
    display: block;
    position: absolute;
    width: 43%;
    max-width: 200px;
    right: -70px;
    top: 8%;
  }
}

.p-indexNewsBox {
  max-width: 1000px;
  margin: 0 auto 40px;
  border-radius: 30px;
  background: rgba(255,255,255,0.8);
  padding: 30px 50px;
  display: flex;
  align-items: center;
  @include m.sp {
    padding: 20px;
    display: block;
    border-radius: 15px;
    margin: 0 auto 30px;
  }
  &_left {
    max-width: 150px;
    @include m.sp {
      max-width: 110px;
      margin: -110px auto 10px;
    }
  }
  &_right {
    margin: 0 0 0 50px;
    width: 100%;
    @include m.sp {
      margin: 0;
    }
  }
}

.p-indexNewsItem {
  padding: 0 0 15px;
  margin: 0 0 10px;
  display: block;
  border-bottom: 1px dotted v.$gray-light;
  @include m.hoverOpa;
  &:last-of-type {
    margin: 0;
    border-bottom: 0;
    padding: 0;
  }
}

.p-indexNewsDate {
  @include m.fontSize(14);
  font-weight: 400;
  padding: 0 0 5px;
  @include m.sp {
    @include m.fontSize(18);
  }
}

.p-indexNewsDesc {
  @include m.fontSize(14);
  font-weight: 500;
  @include m.sp {
    @include m.fontSize(20);
  }
}

.p-indexMenuSec {
  margin: 30px 0;
  @include m.sp {
    margin: 10px 0;
  }
  &_inner {
    margin: 60px 0 0;
    &--cam {
      margin: 30px 0 0;
      & + .p-indexMenuSec_inner {
        margin: 30px 0 0;
      }
    }
  }
  &_title {
    margin: 0 auto -90px;
    max-width: 220px;
  }
  &_bg {
    background: url(/webap/wp-content/themes/pepperlunch/assets/img/top/bg_menu.png);
    height: 440px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 40%;
    border-radius: 40px;
    @include m.sp {
      border-radius: 20px;
      height: 200px;
      background-size: 120%;
    }
  }
}

.p-indexMenuSlids {
  &_item {
    border-radius: 40px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 40%;
    height: 440px;
    @include m.sp {
      border-radius: 20px;
      height: 200px;
    }
    &--01 {
      background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/top/img_menu_slide_01.jpg);
    }
    &--02 {
      background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/top/img_menu_slide_02.jpg);
    }
    &--03 {
      background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/top/img_menu_slide_03.jpg);
    }
  }
}

.p-indexMenuCam {
  display: flex;
  justify-content: space-between;
  @include m.sp {
    display: block;
  }
  &_title {
    width: 100%;
    max-width: 225px;
    margin: 0 10px 0 0;
    @include m.sp {
      width: 100%;
      max-width: 150px;
      margin: 0 auto 20px;
    } 
  }
  &_bnrWrap {
    max-width: 760px;
    width: 100%;
  }
  &_bnrItem {
    margin: 0 0 20px;
    &:last-of-type {
      margin: 0;
    }
  }
}

.p-indexMenuCamBnr {
  margin: 20px 0 0;
  &_link {
    box-shadow: 0 0 5px v.$gray-light02;
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: 33%;
    width: 100%;
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include m.hoverOpa;
  }
}

.p-indexMenuCamTerm {
  @include m.fontSize(14);
  position: relative;
  padding: 0 0 0 10px;
  margin: 0 0 5px;
  font-weight: bold;
  @include m.sp {
    font-size: 1.4rem;
  }
  &:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    margin: auto;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent v.$red;
    @include m.sp {
      top: 0.7rem;
    }
  }
}

.p-indexMenuWrap {
  margin: -140px 0 0;
  @include m.sp {
    margin: -100px 0 0;
  }
}

.p-indexMenu {
  display: flex;
  margin: 0 0 30px;
  @include m.sp {
    display: block;
    max-width: 260px;
    margin: 0 auto;
    margin: 0 auto 30px;
  }
  &_item {
    @include m.hoverOpa;
  }
}

.p-indexShopSec {
  margin: 40px 0;
  background-image: url('/webap/wp-content/themes/pepperlunch/assets/img/top/bg_shop.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  background-position: center 40%;
  @include m.sp {
    padding: 0 0 40px;
    border-radius: 20px;
    margin: 40px 0 0;
  }
  &_inner {
    padding: 120px 20px 60px;
    position: relative;
    @include m.sp {
      padding: 0 10px;
    }
  }
}

.p-indexShopBox {
  max-width: 1000px;
  margin: 0 auto ;
  border-radius: 30px;
  background: v.$white;
  padding: 30px 40px;
  @include m.sp {
    padding: 20px 20px 30px;
    border-radius: 15px;
  }
  &_title {
    max-width: 225px;
    margin: -180px 0 0 -50px;
    @include m.sp {
      max-width: 180px;
      margin: 0 auto;
      position: relative;
      top: -20px;
    }
  }
}

.p-indexShopListsSec {
  &_title {
    text-align: center;
    @include m.fontSize(28);
    font-weight: bold;
    line-height: 1;
    margin: 0 0 10px;
    @include m.sp {
      font-size: 2rem;
    }
  }
}

.p-indexShopLists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include m.tb {
    display: block;
  }
  &_item {
    display: flex;
    border: 2px solid v.$red-deep;
    border-radius: 10px;
    width: 23.5%;
    margin: 0 0 2%;
    @include m.tb {
      border-radius: 80px;
      width: 100%;
    }
    &--big {
      width: 49%;
      @include m.tb {
        width: 100%;
      }
    }
  }
  &_itemRight {
    display: flex;
    margin: 0 0 0 10px;
    @include m.tb {
      margin: 0 auto;
    }
    @include m.sp {
      margin: 0 auto 0 20px;
    }
  }
  &_itemRightSml {
    display: block;
    margin: 0 0 0 10px;
    @include m.tb {
      margin: 0 auto;
    }
    @include m.sp {
      margin: 0 auto 0 0;
    }
  }
  &_br {
    @include m.tb {
      display: none;
    }
  }
  &_Link {
    display: flex;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: v.$red-deep;
    @include m.hoverOpa;
    font-weight: bold;
    @include m.fontSize(16);
    @include m.tb {
      padding: 5px 20px;
      font-size: 1.3rem;
    }
    &--big {
      padding: 40px;
      @include m.fontSize(26);
      @include m.tb {
        padding: 10px 20px;
      }
     
    }
  }
  &_Img {
    margin-right: auto;
    @include m.tb {
      margin-right: 0;
    }
    &--place {
      width: 80px;
      @include m.tb {
        width: 40px;
      }
    }
    &--area {
      width: 110px;
      @include m.tb {
        width: 40px;
      }
    }
    &--logo {
      width: 55px;
      @include m.tb {
        width: 30px;
        margin: 0 25px 0 8px;
      }
    }
    &--takeout {
      width: 33px;
      @include m.tb {
        width: 19px;
        margin: 0 29px 0 15px;
      }
    }
    &--delivery {
      width: 44px;
      @include m.tb {
        width: 25px;
        margin: 0 26px 0 12px;
      }
    }
    &--global {
      width: 46px;
      @include m.tb {
        width: 22px;
        margin: 0 28px 0 15px;
      }
    }
  }
}

.p-indexShopListsArrow {
  margin: 0 0 0 10px;
  width: 20px;
  display: inline-block;
  vertical-align: super;
  @include m.tb {
    width: 18px;
    margin: 0 0 0 5px;
  }
  &--big {
    width: 40px;
    @include m.tb {
      width: 25px;
    }
  }
}

.p-indexShopSerch {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @include m.tb {
    margin: 10px auto 0;
  }
  &_input {
    border: 1px solid v.$gray-thin;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 515px;
    @include m.tb {
      border-radius: 15px;
      max-width: 100%;
    }
  }
  &_btn {
    padding: 13px 30px;
    border-radius: 10px;
    width: calc(100% - 520px);
    background: v.$gray-light02;
    cursor: pointer;
    @include m.hoverOpa;
    @include m.tb {
      border-radius: 15px;
      padding: 5px 22px;
      width: 100%;
      max-width: 60px;
      margin: 0 0 0 5px;
    }
  }
}

.p-indexCampaignSec {
  overflow: hidden;
  @include m.sp {
    overflow:unset;
  }
  &_inner {
    display: flex;
    margin-left: auto;
    padding: 0 20px;
    &.noSlide {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
    }
    @include m.sp {
      display: block;
      width: 100% !important;
      padding: 0;
    }
  }
  &_spacer {
    width: calc(100% + 150px);
    margin: 0 0 40px;
    @include m.sp {
      width: 100%;
    }
    &.noSlide {
      width: auto;
    }
  }
  &_title {
    max-width: 225px;
    width: 100%;
    margin: 0 10px 0 0;
    @include m.sp {
      max-width: 180px;
      margin: -20px auto 20px;
    }
  }
  &_content {
    width: calc(100% - 235px);
    @include m.sp {
      width: 100%;
    }
  }
}

.p-indexCampaignSlide {
  display: flex;
  @include m.sp {
    &.slick-slider {
      width: 90%;
      margin: 0 0 0 auto;
      .slick-list {
        padding: 0 15% 0 0!important;
      }
    }
  }
  &_item {
    padding: 0 10px;
    width: 320px;
  }
  &_link {
    @include m.hoverOpa;
    
  }
  &_imgWrap {
    box-shadow: 0 0 5px v.$gray-light02;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 10px;
    padding-bottom: 65%;
    position: relative;
    height: 0;
  }
  &_img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &_title {
    @include m.fontSize(14);
    position: relative;
    padding: 0 0 0 10px;
    font-weight: bold;
    @include m.sp {
      font-size: 1.4rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
      @include m.sp {
        top: 0.7rem;
      }
    }
  }
  &_txt {
    @include m.fontSize(12);
    @include m.sp {
      font-size: 1.2rem;
    }
  }
}

.p-indexCampaignSlideDots {
 display: none;
 @include m.sp {
   display: block;
   margin: 10px 0 0;
   .slick-dots {
     position: unset;
   }
 }
}



