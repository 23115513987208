@use "global/mixin" as m;
@use "global/variable" as v;

 .c-bnrs {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  gap: 0 20px;
  justify-content: center;
 }

 .c-bnrsMenuSlide.slick-slider,
 .c-bnrsOtherSlide.slick-slider {
  @include m.sp {
    margin: 0 0 0 auto;
    position: relative;
    left: 10px;
    .slick-slide {
        margin-right: 15px;
    }
    .slick-list img {
      width: 100%;
    }
  }
 }

 .c-bnrsMenuSlide.slick-slider {
  @include m.sp {
    width: 90%;
    .slick-list {
      padding: 0 15% 0 0 !important;
    }
  }
 }

 .c-bnrsOtherSlide.slick-slider {
  @include m.sp {
    width: 100%;
    .slick-list {
      padding: 0 5% 0 0 !important;
    }
  }
 }

 .c-bnrsMenuSlideDots,
 .c-bnrsOtherSlideDots {
  display: none;
  @include m.sp {
    display: block;
    margin: 10px 0 0;
    .slick-dots {
      position: unset;
    }
  }
 }

 .c-bnrsMenu {
  width: calc(100%/3);
  &_link {
    //background: v.$white;
    border-radius: 10px;
    box-shadow: 0 0 5px v.$gray-deep;
    display: inline-block;
    @include m.sp {
      border-radius: 2.5vw;
      margin: 0 5px;
    }
  }
  &_img {
    @include m.hoverOpa;
  }
  &_term {
    @include m.fontSize(14);
    font-weight: bold;
    position: relative;
    padding: 0 0 0 10px;
    @include m.sp {
      font-size: 1.4rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
      @include m.sp {
        top: 0.7rem;
      }
    }
  }
  &_desc {
    @include m.fontSize(12);
    @include m.sp {
      font-size: 1.2rem;
    }
  }
 }

 .c-bnrsOther {
  &_link {
    //background: v.$white;
    border-radius: 10px;
    box-shadow: 0 0 5px v.$gray-deep;
    display: inline-block;
    @include m.sp {
      border-radius: 2vw;
      margin: 0 5px;
    }
  }
  &_img {
    @include m.hoverOpa;
  }
 }