@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

input[type="text"],
textarea,
select {
  border: 1px solid v.$gray-thin;
  border-radius: 5px;
  padding: 8px;
  @include m.sp {
    font-size: 1.4rem;
  }
}

.mwform-radio-field-text {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  @include m.sp {
    font-size: 1.4rem;
  }
}

.mwform-radio-field-text:before,
.mwform-radio-field-text:after {
  content: "";
  display: block; 
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.mwform-radio-field-text:before {
  background-color: v.$white;
  border: 2px solid v.$gray;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 10px;
}

.mwform-radio-field-text:after {
  background-color: v.$gray;
  border-radius: 50%;
  opacity: 0;
  width: 15px;
  height: 15px;
  left: 10px;
}

input:checked + .mwform-radio-field-text:after {
  opacity: 1;
}

input[type="radio"] {
  position: absolute;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
}

input[type="submit"] {
  cursor: pointer;
}

.selectWrap {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: translateY(-50%) rotate(-135deg);
  }
  select {
    position: relative;
    padding: 10px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}


.mwform-radio-field {
  cursor: pointer;
  margin: 0 20px 5px 0;  
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

.slick-track {
  display: flex;
  justify-content: center;
}

.slick-dots li {
  margin: 0 5px;
  width: 10px;
  height: 10px;
}

.slick-dots li button {
  height: 10px;
  width: 10px;
}

.slick-dots li button:before {
  font-size: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: v.$black;
}

.slick-dots li.slick-active button:before {
  background-color: v.$red;
  opacity: 1;
}

/*[id^='anc'] {
  margin: -120px 0 0;
  padding: 120px 0 0;
  @include m.tb {
    margin: -80px 0 0;
    padding: 80px 0 0;
  }
}*/

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

.pagination {
  margin: 50px 0 0;
}

.nav-links {
  display: flex;
  justify-content: center;  
}

ul.page-numbers {
  display: flex;
}

a.page-numbers {
  width: 40px;
  height: 40px;
  border: 2px solid v.$orange-pale;
  color: v.$orange-pale;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include m.hoverOpa;
  margin: 0 5px;
  @include m.sp {
    width: 30px;
    height: 30px;
  }
}

.page-numbers.current {
  width: 40px;
  height: 40px;
  background: v.$orange-pale;
  color: v.$white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  @include m.sp {
    width: 30px;
    height: 30px;
  }
}

.page-numbers.dots {
  width: 30px;
  height: 40px;
  color: v.$orange-pale;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  @include m.sp {
    width: 30px;
    height: 30px;
  }
}

.prev.page-numbers,
.next.page-numbers {
  border: 0;
  @include m.sp {
    width: auto;
  }
}

.txt-para {
  margin: 0 0 20px;
  @include m.sp {
    margin: 0 0 1rem;
    font-size: 1.4rem;
  }
}

.txt-link {
  color: v.$navy;
  @include m.hoverOpa;
}

#included-files-fie-on-wp-footer {
  display: none;
}

.lazyload {
  opacity: 0;
}

.is-lazyloaded {
  opacity: 1;
}