@use "global/mixin" as m;
@use "global/variable" as v;

.p-deliveryItemTitle {
  @include m.fontSize(14);
    position: relative;
    padding: 0 0 0 10px;
    font-weight: bold;
    @include m.sp {
      font-size: 1.4rem;
    }
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
      @include m.sp {
        top: 0.7rem;
      }
    }
}

.p-deliveryItemTxt {
  @include m.fontSize(12);
  @include m.sp {
    font-size: 1.2rem;
  }
}

.p-deliveryFlow {
  padding: 60px 50px 30px;
  @include m.sp {
    padding: 40px 20px 10px;
  }
  &_sec {
    margin: 0 0 20px;
  }
  &_tilte {
    border-bottom: 1px solid v.$black;
    padding: 0 0 10px;
    margin: 0 0 20px;
    display: flex;
    align-items: flex-end;
    @include m.fontSize(24);
    font-weight: bold;
    color: v.$red-deep;
    @include m.sp {
      font-size: 1.6rem;
      margin: 0 0 10px;
    }
  }
  &_img {
    margin: 0 30px 0 0;
    &--st01 {
      width: 30px;
    }
    &--st02 {
      width: 33px;
    }
    &--st03 {
      width: 33px;
    }
    &--st04 {
      width: 33px;
    }
    @include m.sp {
      margin: 0 20px 0 0;
    }
  }
  &_content {
    max-width: 670px;
    margin: 0 auto;
    @include m.sp {
      max-width: 670px;
      margin: 0 auto;
      font-size: 1.2rem;
    }
  }
}

.p-deliveryFlowDl {
  display: flex;
  align-items: flex-end;
  margin: 0 0 20px;
  @include m.sp {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 10px;
  }
  &_txt {
    font-weight: bold;
    margin: 0 40px 0 0;
    @include m.sp {
      margin: 0 0 10px;
      width: 100%;
      text-align: center;
    }
  }
  &_txtGrn {
    color: v.$green-light;
  }
  &_txtRed {
    color: v.$red-deep;
  }
  &_app {
    max-width: 180px;
    margin: 0 40px 0 0;
    @include m.hoverOpa;
    @include m.sp {
      max-width: 100%;
      width: 45%;
      margin: 0;
    }
  }
  &_ggl {
    max-width: 202px;
    @include m.hoverOpa;
    @include m.sp {
      max-width: 100%;
      width: 50%;
    }
  }
}

.p-deliveryFlowColBtn {
  display: flex;
  margin: 20px 0 0;
  justify-content: center;
  @include m.sp {
    justify-content: space-between;
  }
  &_item {
    width: 100%;
    max-width: 250px;
    margin: 0 20px;
    @include m.sp {
      width: 47%;
      max-width: 100%;
      margin: 0;
    }
  }
}

.p-deliveryFlowWrap {
  max-width: 900px;
  margin: 0 auto 120px;
  @include m.sp {
    margin: 0 auto 60px;
  }
}
