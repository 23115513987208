@use "global/mixin" as m;
@use "global/variable" as v;
.c-btnWrap {
  text-align: center;
}
.c-btn {
  border-radius: 50px;
  border: 2px solid v.$black;
  display: block;
  padding: 15px 10px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  background: v.$white;
  //margin: 0 auto;
  cursor: pointer;
  @include m.hoverOpa;
  @include m.sp {
    padding: 10px;
    @include m.fontSize(20);
  }
  &--middle {
    max-width: 360px;
    width: 100%;
    @include m.sp {
      max-width: 250px;
    }
  }
  &--small {
    max-width: 200px;
    width: 100%;
    @include m.sp {
      max-width: 170px;
    }
  }
  &--wide {
    width: 100%;
  }
  &_txt {
    &--line {
      text-decoration: underline;
    }
    &--big {
      @include m.fontSize(18);
      @include m.sp {
        @include m.fontSize(22);
      }
    }
    &--bigest {
      @include m.fontSize(26);
      @include m.sp {
        @include m.fontSize(22);
      }
    }
  }
  &_icon-arrow {
    width: 40px;
    margin: 0 0 0 20px;
    @include m.sp {
      width: 30px;
    }
  }
  &--red {
    border-color: v.$red-light;
    color: v.$red-light;
  }
  &--green {
    border-color: v.$green-light;
    color: v.$green-light;
  }
  &--redBg {
    border-color: v.$red-deep;
    background-color: v.$red-deep;
    color: v.$white;
  }
}

.c-btnRdWrapMbtm {
  margin: 0 0 30px;
  text-align: center;
}

.c-btnRdWrapMtop {
  margin: 50px 0 0;
  text-align: center;
  &--col {
    display: flex;
    gap: 20px;
    justify-content: center;
    @include m.sp {
      flex-direction: column;
    }
  }
}

.c-btnRd {
  display: inline-flex;
  border: 2px solid v.$red-deep;
  max-width: 400px;
  width: 100%;
  //margin: 0 auto;
  padding: 20px 40px;
  border-radius: 10px;
  align-items: center;
  @include m.fontSize(16);
  font-weight: bold;
  color: v.$red-deep;
  @include m.hoverOpa;
  @include m.sp {
    border-radius: 40px;
    padding: 10px 40px;
  }
  @include m.sp {
    width: 100%;
    max-width: 100%;
  }
  &_icon {
    &--takeout {
      width: 33px;
      @include m.sp {
        width: 20px;
      }
    }
    &--pdf {
      width: 36px;
      @include m.sp {
        width: 20px;
      }
    }
    &--delivery {
      width: 43px;
      @include m.sp {
        width: 30px;
      }
    }
  }
  &_txt {
    margin: 0 auto;
  }
  &_arrow {
    width: 20px;
  }
}

.c-btnSq {
  display: inline-flex;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid v.$black;
  @include m.hoverOpa;
  &--yellow {
    border: 2px solid v.$orange-pale;
    color: v.$orange-pale;
  }
  &_iconArrow {
    width: 15px;
  }
  &_txt {
    margin: 0 10px;
    @include m.fontSize(14);
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}

