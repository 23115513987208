@use "global/mixin" as m;
@use "global/variable" as v;

 .c-titleImgWrap {
  max-width: 225px;
  width: 20%;
  position: absolute;
  display: inline-block;
  top: -60px;
  left: 40px;
  z-index: 2;
  &--cunsultation {
    max-width: 225px;
    width: 22%;
    left: 30px;
  }
  &--contact {
    max-width: 228px;
    width: 19%;
  }
  @include m.sp {
    left: 0;
    right: 0;
    margin: auto;
    top: -30px;
    width: min(50%, 300px);
    max-width: initial;
  }
 }

 .c-titleArticle {
    padding-left: 16%;
    @include m.desktop {
      padding-left: 21%;
    }
    @include m.sp {
      padding: 0;
      margin: 0 0 10px;
      font-size: 1.6rem;
    }
    &_date {
      @include m.fontSize(14);
      @include m.sp {
        font-size: 1.4rem;
      }
    }
    &_txt {
      @include m.fontSize(28);
      font-weight: bold;
    }
 }

 .c-titleArticleChild {
  @include m.fontSize(24);
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.75rem;  
  }
 }