@use "global/mixin" as m;
@use "global/variable" as v;

.l-box {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 60px;
  position: relative;
  border-radius: 30px;
  @include m.sp {
    padding: 0 20px 30px;
  }
  &--white {
    background: v.$white;
    margin: 0 auto 60px;
    @include m.sp {
      border-radius: 10px;
      margin: 0 auto 30px;
      box-shadow: 0 0 6px v.$gray-light02;
    }
  }
  &--whiteSpNone {
    @include m.sp {
      background: transparent;
      margin: 0 auto;
      box-shadow: none;
      padding: 0 0 30px;
    }
  }
  &--pink {
    background: v.$pink-clear;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: 900px;
    margin: 0 auto 60px;
    padding: 0;
    box-shadow: 0 0 6px v.$gray-light02;
    @include m.sp {
      padding: 0;
      margin: 0 0 40px;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &--pinkInBox {
    background: v.$pink-clear;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    @include m.sp {
      padding: 0;
      margin: 0 auto;
      box-shadow: 0 0 6px v.$gray-light02;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &--titleTag {
    padding: 150px 20px;
  }
  &_inner {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    &--titleWrap {
      @include m.sp {
        padding: 42% 0 0;
      }  
    }
  }
}