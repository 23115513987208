@use "global/mixin" as m;
@use "global/variable" as v;


.p-restaurant {
  margin: min(8vw,110px) 0 0;
  @include m.sp {
    margin: 0;
  }
}
