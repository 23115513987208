@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1400px) {
    @content;
  }
}

@mixin tb {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin fontSize($size) {
  font-size: $size/16 + rem;
}

@mixin lineHeight($height,$size) {
  line-height: $height/$size;
}

/*900-768のみフォントが拡大縮小*/
@mixin fontSizeCalc($fMin,$fMax) {
  $fMinCalc: $fMin + px;
  font-size: calc($fMinCalc + ($fMax - $fMin) * (100vw - 1000px)/400);
}

@mixin hoverOpa {
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}
