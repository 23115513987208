@use "global/mixin" as m;
@use "global/variable" as v;

.p-faq {
  margin: 120px 0 0;
  @include m.sp {
    margin: 0;
  }
}

.p-faqAncHead {
  display: none;
  @include m.sp {
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.p-faqAnc {
  @include m.sp {
    border: 1px solid v.$gray-light03;
    border-radius: 30px;
    color: v.$red-deep;
    margin: 0 0 30px;
  }
  &_inner {
    @include m.sp {
      display: none;
    }
  }
  &_cont {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 50px;
    width: 100%;
    max-width: 100%;
    gap: 15px 1.3%;
    @include m.sp {
      display: block;
      margin: 0;
    }
  }
  &_item {
    border: 2px solid v.$red-deep;
    border-radius: 10px;
    color: v.$red-deep;
    width: 24%;
    text-decoration: none;
    @include m.sp {
      font-weight: bold;
      color: v.$black;
      text-decoration: none;
      font-size: 1.2rem;
      border: none;
      border-bottom: 1px dotted v.$gray-light03;
      width: 100%;
      border-radius: 0;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  &_itemLink {
    width: 100%;
    display: block;
    text-align: center;
    padding: 30px 10px;
    @include m.hoverOpa;
    font-weight: bold;
    @include m.sp {
      display: block;
      padding: 10px 0;
      text-align: left;
    }
  }
}



.p-faqSec {
  margin: 0 0 20px;
  &_title {
    @include m.fontSize(24);
    font-weight: bold;
    color: v.$white;
    background: v.$red-deep;
    padding: 10px 15px 13px;
    border-radius: 5px;
    margin: 0 0 20px;
    @include m.sp {
      font-size: 1.7rem;
    }
  }
  &_inner {
    width: 96%;
    max-width: 960px;
    padding: 0 0 30px;
    margin: 0 auto 30px;
    border-bottom: 1px dotted v.$gray;
    &:last-of-type {
      margin: 0 auto;
      border-bottom: 0;
    }
    @include m.sp {
      padding: 0 0 10px;
      margin: 0 auto 10px;
    }
  }
  &_q,
  &_a {
    padding: 2px 0 3px 40px;
    background-repeat: no-repeat;
    background-size: 28px;
    @include m.sp {
      background-size: 5%;
      padding: 0 0 0 8%;
    }
  }
  &_q {
    @include m.fontSize(18);
    font-weight: bold;
    background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/faq/icon_q.svg);
    margin: 0 0 20px;
    @include m.sp {
      margin: 0 0 10px;
      font-size: 1.4rem;
    }   
  }
  &_a {
    @include m.fontSize(16);
    background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/faq/icon_a.svg);
    @include m.sp {
      font-size: 1.2rem
    } 
  }
}
