@use "global/mixin" as m;
@use "global/variable" as v;

.l-header {
  padding: 20px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  @include m.tb {
    padding: 10px;
  }
  &_logoWrap {
    width: 100%;
    max-width: 110px;
    @include m.desktop {
      max-width: 80px;
    }
    @include m.tb {
      max-width: 60px;
    }
  }
  &_navLeft {
    margin: 0 0 0 20px;
    background: v.$white;
    border-radius: 40px;
    padding: 10px 20px;
    @include m.tb {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;
      padding: 5px 20px;
    }
  }
  &_navRight {
    margin-left: auto;
    @include m.tb {
      display: none;
    }
  }
}

.l-headerGnav {
  display: flex;
  font-family: v.$corpo;
  &_item {
    margin: 0 30px 0 0;
    @include m.desktop {
      margin: 0 10px 0 0;
    }
    @include m.tb {
      margin: 0 20px 0 0;
    }
    &--hidden-tb {
      @include m.tb {
        display: none;
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &_link {
    display: flex;
    @include m.fontSize(22);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      display: block;
      width: 0;
      height: 2px;
      background: v.$red;
      transition: 0.3s;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
    @include m.desktop {
      @include m.fontSize(16);
    }
    @include m.tb {
      @include m.fontSize(16);
      padding: 0;
    }
    @include m.sp {
      font-size: 1.6rem;
    }    
  }
  &_img {
    margin: 0  10px 0 0;
    @include m.desktop {
      margin: 0  5px 0 0;
    }    
    &--shop {
      width: 23px;
      @include m.desktop {
        width: 20px;
      }
    }
    &--menu {
      width: 16px;
      @include m.desktop {
        width: 15px;
      }
    }
    &--takeout {
      width: 18px;
      @include m.desktop {
        width: 15px;
      }
    }
    &--delivery {
      width: 24px;
      @include m.desktop {
        width: 21px;
      }
    }
    &--mobile {
      width: 17px;
      @include m.desktop {
        width: 14px;
      }
    }
    &--beef {
      width: 29px;
      @include m.desktop {
        width: 26px;
      }
    }
  }
}

.l-headerSubnav {
  display: flex;
  @include m.tb {
    justify-content: center;
    margin: 20px 0;
  }
  &_item {
    border-radius: 100%;
    //background: v.$white;
    &:nth-of-type(2) {
      margin: 0 10px;
      @include m.tb {
        margin: 0 25px;
      }
    }
  }
  &_link {
    border-radius: 100%;
    @include m.fontSize(10);
    width: 80px;
    height: 80px;
    display: inline-flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include m.lineHeight(12,10);
    @include m.hoverOpa;
    @include m.desktop {
      width: 70px;
      height: 70px;
    }
    @include m.sp {
      font-size: 1rem;
    }
    &--contact {
      background: v.$gray-deep;
      color: v.$white;
    }
    &--appli {
      background: v.$yellow;
      color: v.$gray-dark;
    }
    &--company {
      background: v.$red;
      color: v.$white;
    }
  }
  &_img {
    margin: 0 0 5px;
    &--contact {
      width: 25px;
      @include m.desktop {
        width: 22px;
      }
    }
    &--appli {
      width: 25px;
      @include m.desktop {
        width: 22px;
      }
    }
    &--company {
      width: 23px;
      @include m.desktop {
        width: 20px;
      }
    }
  }
}

.l-headerSpnavBtn {
  display: none;
  @include m.tb {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &_barTop,
    &_barMiddle,
    &_barBottom {
      height: 1px;
      background: v.$black;
      display: block;
      width: 100%;
    }
    &_barTop {
      margin: 0 0 5px;
    }
    &_barBottom {
      margin: 5px 0 0;
    }
  }
}

.l-headerSpnavMenu {
  background-position: left top,left bottom,right bottom;
  background-size: 80%,20%,20%;
  background-color: v.$orange-pale;
  background-repeat: no-repeat;
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 17px 10px 10px;
  overflow: auto;
  &_bgImg01 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 85%;
  }
  &_bgImg02 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 20%;
    max-width: 100px;
  }
  &_bgImg03 {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 20%;
    max-width: 150px;
  }
  &_logoWrap {
    text-align: center;
    margin: 0 0 15px;
  }
  &_logoLink {
    max-width: 90px;
    width: 100%;
    display: inline-block;
  }
}

.l-headerSpMenuBtnCls {
  text-align: right;
  &_wrap {
    padding: 17px 20px;
    border-radius: 25px;
    background: v.$white;
    display: inline-flex;
    flex-direction: column;
  }
  &_bar {
    height: 1px;
    width: 25px;
    display: inline-block;
    background: #000;
    transform: rotate(45deg);
    &:last-of-type {
      position: relative;
      left: 0px;
      top: -1px;
      transform: rotate(-45deg);
    }
  }
}

.l-headerSpMenuLists {
  &_ttl {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_state {
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0 10px 0 0;
    &--plus {
      &:before {
        position: absolute;
        content:"";
        height: 1px;
        width: 100%;
        background: v.$black;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:after {
        position: absolute;
        content:"";
        height: 100%;
        width: 1px;
        background: v.$black;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &--minus {
      &:before {
        position: absolute;
        content:"";
        height: 1px;
        width: 100%;
        background: v.$black;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  &_item {
    border-top: 1px solid v.$black-iron;
    border-bottom: 1px solid v.$black-iron;
    padding: 10px 0;
    &:nth-of-type(n + 2) {
      border-top: 0;
    }
  }
  &_link {
    display: flex;
    font-size: 2.2rem;
    font-family: v.$corpo;
  }
  &_img {
    margin: 0 10px 0 0;
    &--shop {
      width: 25px;
    }
    &--menu {
      width: 20px;
      margin: 0 15px 0 0;
    }
    &--takeout {
      width: 20px;
      margin: 0 15px 0 0;
    }
    &--delivery {
      width: 28px;
      margin: 0 7px 0 0;
    }
    &--mobile {
      width: 22px;
      margin: 0 13px 0 0;
    }
    &--beef {
      width: 28px;
      margin: 0 10px 0 0;
    }
  }
}

.l-headerSpMenuListsChildWrap {
  display: none;
}

.l-headerSpMenuListsChild {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
  &.is-close {
    display: none;
  }
  &_item {
  width: 50%;    
  padding: 5px 0 5px 10px;
  position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 40%;
      left: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent v.$red;
      @include m.sp {
        top: 2.5vw;
      }
    }
  }
  &_link {
    font-size: 1.1rem;
    color: v.$black-iron;
    display: inline-block;   
  }
}