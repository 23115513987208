@use "global/mixin" as m;
@use "global/variable" as v;

.l-body {
  background: url(/webap/wp-content/themes/pepperlunch/assets/img/bg_top_pc.png),url(/webap/wp-content/themes/pepperlunch/assets/img/bg_body.jpg);
  background-repeat: no-repeat,repeat;
  background-size: 100%,100%;
  @include m.sp {
    background-image: url(/webap/wp-content/themes/pepperlunch/assets/img/bg_top_sp.png),url(/webap/wp-content/themes/pepperlunch/assets/img/bg_body.jpg);
  }
}