@use "global/mixin" as m;
@use "global/variable" as v;

.c-fade {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
}

.c-fadeIn {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}