@use "global/mixin" as m;
@use "global/variable" as v;

 .c-bdr {
  margin: 40px 0;
  text-align: center;
  @include m.sp {
    margin: 20px 0;
  }
  &_inner {
    display: inline-block;
    width: 80px;
    height: 3px;
    background: v.$gray-smork;
    vertical-align: super;    
  }
  &_innerAll {
    display: block;
    width: 100%;
    height: 1px;
    background: v.$gray-ash;
    vertical-align: super;
  }
 }